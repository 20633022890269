<template>
  <section class="truncated-list">
    <slot v-for="item in visibleItems" v-bind="item" />
    <template v-if="initialLimit < items.length">
      <cta-button
        v-if="!showAll"
        :title="translations.truncatedList.showMore"
        :action="
          () => {
            showAll = true;
            $emit('is-showing-all', showAll);
          }
        "
        class="truncated-list__btn"
        theme="transparent"
      />
      <cta-button
        v-else
        :title="translations.truncatedList.showLess"
        :action="
          () => {
            showAll = false;
            $emit('is-showing-all', showAll);
          }
        "
        class="truncated-list__btn"
        theme="transparent"
      />
    </template>
  </section>
</template>

<script setup lang="ts" generic="T">
import { computed, ref } from "vue";

import { translations } from "@/infrastructure/translations";

import CtaButton from "@/components/buttons/cta-button.vue";

const props = defineProps<{ initialLimit: number; items: T[]; sortMethod?: (a: T, b: T) => number }>();

defineEmits<{ (e: "is-showing-all", is: boolean): void }>();

const showAll = ref(false);

const visibleItems = computed(() => {
  const sortedItems = [...props.items].sort(props.sortMethod);
  return showAll.value ? sortedItems : sortedItems.slice(0, props.initialLimit);
});
</script>

<style lang="scss" scoped>
.truncated-list {
  display: flex;
  flex-flow: column;
  padding: 10px 8px 10px 8px;

  &__btn {
    align-self: flex-start;
  }
}
</style>
